import { ClipboardModule } from '@angular/cdk/clipboard';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { HowdenAlertsModule, HowdenSharedModule, HowdenTableModule } from '@howdeniberia/core-front';

import { CredentialEditDialogComponent } from './components/credential-edit-dialog/credential-edit-dialog.component';
import { CredentialsListComponent } from './components/credentials-list/credentials-list.component';
import { CredentialsRoutingModule } from './credentials-routing.module';
import { CredentialsPageComponent } from './pages/credentials-page/credentials-page.component';

@NgModule({
  declarations: [
    CredentialsListComponent,
    CredentialsPageComponent,
    CredentialEditDialogComponent
  ],
  imports: [
    CredentialsRoutingModule,
    CommonModule,
    ReactiveFormsModule,
    MatButtonModule,
    MatInputModule,
    MatCardModule,
    MatFormFieldModule,
    MatIconModule,
    MatDialogModule,
    MatSlideToggleModule,
    ClipboardModule,
    HowdenAlertsModule,
    HowdenTableModule,
    HowdenSharedModule
  ],
  exports: [
    CredentialEditDialogComponent
  ]
})
export class CredentialsModule { }
