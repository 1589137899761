import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Observable } from 'rxjs';

import {
  CredentialEditDialogComponent,
} from './../../../features/credentials/components/credential-edit-dialog/credential-edit-dialog.component';
import { ICredentialEditdataDialogResult } from './../models/ui/credential-edit-data-dialog-result';
import { CredentialViewModel } from './../models/ui/credential-view-model';

@Injectable({
  providedIn: 'root'
})
export class ShowEditCredentialService {

  private dialogRef!: MatDialogRef<CredentialEditDialogComponent>;

  constructor(
    private dialog: MatDialog
  ) { }

  public open(credential?: CredentialViewModel | null): Observable<CredentialViewModel> {
    return new Observable(observer => {
      this.dialogRef = this.dialog.open(CredentialEditDialogComponent, {
        disableClose: false,
        width: 'auto',
        data: {
          value: credential
        } as ICredentialEditdataDialogResult
      });
      this.dialogRef.afterClosed().subscribe(result => {
        observer.next(result);
        observer.complete();
      });
    });
  }
}
