import { NgModule, Provider } from '@angular/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MAT_FORM_FIELD_DEFAULT_OPTIONS } from '@angular/material/form-field';
import { MatPaginatorIntl } from '@angular/material/paginator';
import {
  getPaginatorIntl,
  HowdenCoreModule,
  HowdenErrorPagesModule,
  HowdenPageTitleModule,
  HowdenWithoutMenuLayoutModule,
  IHowdenEnvironment,
  IPackageJsonWrapper,
  NotificationsService,
} from '@howdeniberia/core-front';
import 'moment/locale/es';
import { ApiConfigurations } from 'src/environments/api-configurations';
import { environment } from 'src/environments/environment';
import packageJson from '../../package.json';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ClavesCiasApiService } from './core/services/api/claves-cias-api.service';
import { ClavesCiasContract } from './core/services/api/claves-cias-contract';
import { CredentialsModule } from './features/credentials/credentials.module';

export const AppProviders: Provider[] = [
  MatDatepickerModule,
  // MockService,
  NotificationsService,
  { provide: MAT_FORM_FIELD_DEFAULT_OPTIONS, useValue: { appearance: 'fill', } },
  { provide: ClavesCiasContract, useClass: ClavesCiasApiService },
  { provide: MatPaginatorIntl, useValue: getPaginatorIntl() }
];

@NgModule({
  declarations: [AppComponent],
  imports: [
    AppRoutingModule,
    HowdenWithoutMenuLayoutModule,
    HowdenPageTitleModule,
    HowdenErrorPagesModule,
    CredentialsModule,
    HowdenCoreModule.forRoot(
      environment as IHowdenEnvironment,
      packageJson as IPackageJsonWrapper,
      ApiConfigurations
    )
  ],
  providers: AppProviders,
  bootstrap: [AppComponent]
})
export class AppModule {}
