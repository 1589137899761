import { GENERAL_CONSTANTS, HowdenApiEnviroment, IHowdenApiInfo } from '@howdeniberia/core-front';
import { APP_CONSTANTS } from 'src/app/core/constants/general-constants';

export const ApiConfigurations: IHowdenApiInfo[] = [
  {
    name: GENERAL_CONSTANTS.ApiSecurityName,
    paths: {
      local: 'https://localhost:7249',
      develop: 'https://how-apisecurity.app-dev.howdeniberia.com',
      staging: 'https://how-apisecurity.app-staging.howdeniberia.com',
      production: 'https://how-apisecurity.app.howdeniberia.com',
      devHowdenGroup: 'https://how-apisecurity.app-dev.howdeniberia.com',
      preHowdenGroup: 'https://how-apisecurity.app-staging.howdeniberia.com',
      proHowdenGroup: 'https://how-apisecurity.app.howdeniberia.com'
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true,
  },
  {
    name: APP_CONSTANTS.API_CLAVES_CIAS_NAME,
    paths: {
      local: 'https://localhost:7198',
      develop: 'https://how-ib-apiclavescias-dev.azurewebsites.net',
      staging: 'https://how-ib-apiclavescias-pre.azurewebsites.net',
      production: 'https://how-apiclavescias.app.howdeniberia.com', //Custom domain para poder acceder desde fuera del grupo (desde el iPad)
      devHowdenGroup: 'https://how-ib-apiclavescias-dev.azurewebsites.net',
      preHowdenGroup: 'https://how-ib-apiclavescias-pre.azurewebsites.net',
      proHowdenGroup: 'https://how-apiclavescias.app.howdeniberia.com' //Custom domain para poder acceder desde fuera del grupo (desde el iPad)
    },
    useEnviroment: HowdenApiEnviroment.ENVIROMENT_CHOOSE,
    needsToken: true,
  }
] as IHowdenApiInfo[];
