import { Injectable } from '@angular/core';
import { HowdenHttpClientService } from '@howdeniberia/core-front';
import { Observable } from 'rxjs';
import { APP_CONSTANTS } from 'src/app/core/constants/general-constants';
import { ICredentialDto } from 'src/app/core/models/api/credential-dto';
import { ICredentialDataFilterDto } from 'src/app/core/models/api/credential-filter-dto';
import { INewCredentialDto } from 'src/app/core/models/api/new-credential-dto';

import { IDeleteCredentialDto } from '../../models/api/delete-credential-dto';
import { ClavesCiasContract } from './claves-cias-contract';


@Injectable({
  providedIn: 'root'
})
export class ClavesCiasApiService implements ClavesCiasContract {

  apiName = APP_CONSTANTS.API_CLAVES_CIAS_NAME;
  basePath = "api/credentials"

  constructor(
    private httpClient: HowdenHttpClientService
  ) { }

  public GetCredentials(): Observable<ICredentialDto[]> {
    return this.httpClient.get<ICredentialDto[]>(this.apiName, this.basePath);
  }

  public RegisterCredential(newCredentialDto: INewCredentialDto): Observable<ICredentialDto> {
    return this.httpClient.post<ICredentialDto>(this.apiName, this.basePath, newCredentialDto);
  }

  public FindCredentials(filter: ICredentialDataFilterDto): Observable<ICredentialDto[]> {
    const path = `${this.basePath}/find`;
    return this.httpClient.post<ICredentialDto[]>(this.apiName, path, filter);
  }

  public UpdateCredential(credentialDto: ICredentialDto): Observable<ICredentialDto> {
    return this.httpClient.put<ICredentialDto>(this.apiName, this.basePath, credentialDto);
  }

  public ReactivateCredential(credentialId: number): Observable<boolean> {
    const path = `${this.basePath}/${credentialId}`;
    return this.httpClient.put<boolean>(this.apiName, path);
  }

  public DeleteCredential(deleteCredentialDto: IDeleteCredentialDto): Observable<ICredentialDto> {
    return this.httpClient.delete<ICredentialDto>(this.apiName, this.basePath, { body: deleteCredentialDto });
  }

}
