import { Observable } from 'rxjs';
import { ICredentialDto } from 'src/app/core/models/api/credential-dto';
import { INewCredentialDto } from 'src/app/core/models/api/new-credential-dto';

import { ICredentialDataFilterDto } from '../../models/api/credential-filter-dto';
import { IDeleteCredentialDto } from '../../models/api/delete-credential-dto';

export abstract class ClavesCiasContract {
  public abstract GetCredentials(): Observable<Array<ICredentialDto>>;
  public abstract RegisterCredential(newCredentialDto: INewCredentialDto): Observable<ICredentialDto>;
  public abstract FindCredentials(filter: ICredentialDataFilterDto): Observable<Array<ICredentialDto>>;
  public abstract UpdateCredential(credentialDto: ICredentialDto): Observable<ICredentialDto>;
  public abstract ReactivateCredential(credentialId: number): Observable<boolean>;
  public abstract DeleteCredential(deleteCredentialDto: IDeleteCredentialDto): Observable<ICredentialDto>;
}
