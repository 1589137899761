import { Clipboard } from '@angular/cdk/clipboard';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
  DialogResultTypes,
  HowdenAlertService,
  HowdenAskForDataService,
  HowdenTableComponent,
  IButtonActionData,
  IHowdenAskForData,
  IHowdenYesNoData,
  YesNoService,
} from '@howdeniberia/core-front';
import { IDeleteCredentialDto } from 'src/app/core/models/api/delete-credential-dto';
import { ClavesCiasContract } from 'src/app/core/services/api/claves-cias-contract';
import { CredentialRowModel } from 'src/app/features/credentials/models/ui/credential-row.model';
import { CredentialViewModel } from 'src/app/features/credentials/models/ui/credential-view-model';
import { ShowEditCredentialService } from 'src/app/features/credentials/services/show-edit-credential.service';

import { ICredentialDto } from './../../../../core/models/api/credential-dto';
import { CredentialsMapper } from './../../mappers/credential-mapper';

@Component({
  selector: 'howden-credentials-list',
  templateUrl: './credentials-list.component.html',
  styleUrls: ['./credentials-list.component.scss']
})
export class CredentialsListComponent implements OnInit {

  @ViewChild(HowdenTableComponent) table!: HowdenTableComponent;
  pageSize = 10;
  model: CredentialRowModel = new CredentialRowModel();
  dataRaw: CredentialViewModel[] = [];
  data: CredentialViewModel[] = [];
  C_DELETED = 'filt_deleted';
  private mapper: CredentialsMapper = new CredentialsMapper();

  form: FormGroup = new FormGroup({
    [this.C_DELETED]: new FormControl(false)
  });

  constructor(
    private askForDataSrv: HowdenAskForDataService,
    private clavesCiasSrv: ClavesCiasContract,
    private showEditSrv: ShowEditCredentialService,
    private yesNoSrv: YesNoService,
    private alertSrv: HowdenAlertService,
    private clipBoardSrv: Clipboard
  ) { }

  ngOnInit(): void {
    this.reload();
  }

  public reload(): void {
    this.clavesCiasSrv.GetCredentials().subscribe({
      next: (apiData) => {
        // this.data = this.mapper.credentialDtoArrayToViewModelArray(apiData);
        this.dataRaw = this.mapper.credentialDtoArrayToViewModelArray(apiData);
        this.filterData();
      }
    });
  }

  filterData(): void {
    const showDeleted = this.form.get(this.C_DELETED)?.value;
    const filtered = showDeleted ?
      this.dataRaw.filter(x => x.deleted === true) :
      this.dataRaw.filter(x => x.deleted === false)
      ;
    this.data = filtered;
    // this.table.data = this.data;
  }

  buttonClick(data: IButtonActionData): void {
    switch (data.buttonName) {
      case this.model.BTN_DELETE:
        this.deleteCredential(data.row);
        break;
      case this.model.BTN_EDIT:
        this.editCredential(data.row);
        break;
      case this.model.BTN_COPY_PWD:
        this.copyPassword(data.row);
        break;
      case this.model.BTN_COPY_USR:
        this.copyUser(data.row);
        break;
      case this.model.BTN_REACTIVATE:
        this.reactivateCredential(data.row);
        break;
    }
  }

  editCredential(data: CredentialViewModel): void {
    this.showEditSrv.open(data).subscribe(result => {
      if (result) {
        this.clavesCiasSrv.UpdateCredential(this.mapper.viewModelToCredentialDto(result)).subscribe({
          next: (updatedRow) => {
            this.updateOnDataRaw(updatedRow);
            this.alertSrv.info(`Credenciales actualizadas para ${updatedRow.company}`, '');
          }
        });
      }
    });
  }

  deleteCredential(data: CredentialViewModel): void {
    const options = {
      title: `Seguro de eliminar la credencial ${data.company}`,
      allowCancel: false,
      cancelActionText: 'mejor no',
      okActionText: 'si seguro'
    } as IHowdenYesNoData
    this.yesNoSrv.open(options).subscribe(result => {
      if (result) {
        const options = {
          title: 'Debes indicar un motivo',
        } as IHowdenAskForData;
        this.askForDataSrv.open(options).subscribe({
          next: (reason) => {
            if (reason.result === DialogResultTypes.OK) {
              const deleteObj = {
                credentialId: data.credentialId,
                reasonToDelete: reason.resultData
              } as IDeleteCredentialDto;
              this.clavesCiasSrv.DeleteCredential(deleteObj).subscribe({
                next: (result) => {
                  this.updateOnDataRaw(result);
                  this.alertSrv.success(`eliminada! ${data.company}`, '');
                }
              });
            }
          }
        });
      }
    });
  }

  reactivateCredential(data: CredentialViewModel): void {
    if (!data.deleted) {
      this.alertSrv.info('Credencial ya activa!', '');
      return;
    }
    const options = {
      title: `Seguro de re activar la credencial ${data.company}`,
      allowCancel: false,
      cancelActionText: 'mejor no',
      okActionText: 'si seguro'
    } as IHowdenYesNoData
    this.yesNoSrv.open(options).subscribe(result => {
      if (result) {
        this.clavesCiasSrv.ReactivateCredential(data.credentialId).subscribe({
          next: (result) => {
            if (result) {
              data.deleted = false;
              this.updateOnDataRaw(this.mapper.viewModelToCredentialDto(data));
              this.alertSrv.success(`reactivada! ${data.company}`, '');
            }
          }
        });
      }
    });
  }

  public updateOnDataRaw(credentialDto: ICredentialDto): void {
    let index = this.dataRaw.findIndex(x => x.credentialId === credentialDto.credentialId);
    this.dataRaw[index] = this.mapper.credentialDtoToViewModel(credentialDto);
    this.filterData();
  }

  public insertOnDataraw(credentialDto: ICredentialDto): void {
    let index = this.dataRaw.findIndex(x => x.credentialId === credentialDto.credentialId);
    if (index > -1) {
      return;
    }
    this.dataRaw.push(this.mapper.credentialDtoToViewModel(credentialDto));
    this.filterData();
  }

  copyUser(data: CredentialViewModel): void {
    this.clipBoardSrv.copy(data.user);
    this.alertSrv.info('Usuario copiado!', '');
  }

  copyPassword(data: CredentialViewModel): void {
    this.clipBoardSrv.copy(data.password);
    this.alertSrv.info('Contraseña copiada!', '');
  }

}
