import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

import { CredentialsPageComponent } from './pages/credentials-page/credentials-page.component';

// children of '' route
export const CredentialRoutes = [
  {
    path: '',
    component: CredentialsPageComponent,
    title: $localize`:@@app.route.main:Credentials List`,
    // data: {
    //   icon: 'security',
    //   linkText: $localize`:@@app.route.rolestest:Roles Test`,
    //   roles: [],
    // },
  }
] as Routes;
@NgModule({
  declarations: [],
  imports: [RouterModule.forChild(CredentialRoutes)],
  exports: [RouterModule],
})
export class CredentialsRoutingModule { }
