<div id="action-buttons" class="flex f-gap--10px">
  <button
    mat-fab
    [howdenRolEvalHide]="['HowdenClavesCiasAdmin']"
    color="warn"
    (click)="newCredential()"
  >
    <mat-icon>add</mat-icon>
  </button>
  <button mat-fab color="info" (click)="reload()">
    <mat-icon>sync</mat-icon>
  </button>
</div>
<div>
  <howden-credentials-list></howden-credentials-list>
</div>
