import { ColumnPipeTypes, IHowdenColumnConfig, ITableButtonConfig, TableButtonColors } from '@howdeniberia/core-front';

export class CredentialRowModel {

  public readonly BTN_EDIT = 'editar';
  public readonly BTN_DELETE = 'borrar';
  public readonly BTN_REACTIVATE = 'reactivar';
  public readonly BTN_COPY_USR = 'copy_usr';
  public readonly BTN_COPY_PWD = 'copy_pwd';

  constructor() {

  }

  public get columns(): IHowdenColumnConfig[] {
    const result = [
      {
        fieldName: 'delegation',
        fieldHeader: 'Delegación',
        canSort: true,
        // canFilter: true
      },
      {
        fieldName: 'company',
        fieldHeader: 'Compañía',
        canSort: true,
        // canFilter: true,
      },
      {
        fieldName: 'user',
        fieldHeader: 'Usuario'
      },
      {
        fieldName: 'password',
        fieldHeader: 'Contraseña',
        pipeToApply: ColumnPipeTypes.PASSWORD
      },
      {
        fieldName: 'url',
        fieldHeader: 'Url',
        pipeToApply: ColumnPipeTypes.URL
      }
    ] as IHowdenColumnConfig[];
    return result;
  }

  public get buttons(): ITableButtonConfig[] {
    const result = [
      {
        name: this.BTN_EDIT,
        icon: 'edit',
        tooltip: 'editar clave',
        color: TableButtonColors.ACCENT
      },
      {
        name: this.BTN_DELETE,
        icon: 'delete',
        tooltip: 'borrar el registro',
        color: TableButtonColors.WARN,
      },
      {
        name: this.BTN_COPY_USR,
        icon: 'content_copy',
        tooltip: 'copiar usuario',
        color: TableButtonColors.NONE
      },
      {
        name: this.BTN_COPY_PWD,
        icon: 'file_copy',
        tooltip: 'copiar contraseña',
        color: TableButtonColors.INFO,
      },
      {
        name: this.BTN_REACTIVATE,
        icon: 'change_circle',
        tooltip: 'reactivar',
        color: TableButtonColors.SUCCESS,
      }
    ] as ITableButtonConfig[];
    return result;
  }
}
