import { FormControl, FormGroup, Validators } from '@angular/forms';
import { StringValidators } from '@howdeniberia/core-front';

import { CredentialViewModel } from './credential-view-model';

export class EditCredentialViewModel {

  private _form: FormGroup;

  constructor() {
    this._form = this.createForm();
  }

  /* #region  Form Field Names */
  public readonly C_CREDENTIALID = 'credentialid';
  public readonly C_DELEGATION = 'delegation';
  public readonly C_COMPANY = 'company';
  public readonly C_USER = 'user';
  public readonly C_PASSWORD = 'password';
  public readonly C_URL = 'url';
  /* #endregion */

  private createForm(): FormGroup {
    return new FormGroup({
      [this.C_CREDENTIALID]: new FormControl('', []),
      [this.C_DELEGATION]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_COMPANY]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_USER]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
      [this.C_PASSWORD]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart, Validators.minLength(3)]),
      [this.C_URL]: new FormControl('', [Validators.required, StringValidators.noSpacesEndStart]),
    });
  }

  public loadForm(data: CredentialViewModel): void {
    this.credentialId = data.credentialId ? data.credentialId : 0;
    this.company = data.company;
    this.user = data.user;
    this.password = data.password;
    this.url = data.url;
    this.delegation = data.delegation;
  }

  /* #region  Properties */
  public get form(): FormGroup {
    return this._form;
  }

  public get delegation(): string {
    return this._form.get(this.C_DELEGATION)?.value;
  }

  public set delegation(value: string) {
    this._form.get(this.C_DELEGATION)?.setValue(value);
  }

  public get credentialId(): number {
    return this._form.get(this.C_CREDENTIALID)?.value;
  }

  public set credentialId(value: number) {
    this._form.get(this.C_CREDENTIALID)?.setValue(value);
  }

  public get company(): string {
    return this._form.get(this.C_COMPANY)?.value;
  }

  public set company(value: string) {
    this._form.get(this.C_COMPANY)?.setValue(value);
  }

  public get password(): string {
    return this._form.get(this.C_PASSWORD)?.value;
  }

  public set password(value: string) {
    this._form.get(this.C_PASSWORD)?.setValue(value);
  }

  public get user(): string {
    return this._form.get(this.C_USER)?.value;
  }

  public set user(value: string) {
    this._form.get(this.C_USER)?.setValue(value);
  }

  public get url(): string {
    return this._form.get(this.C_URL)?.value;
  }

  public set url(value: string) {
    this._form.get(this.C_URL)?.setValue(value);
  }
  /* #endregion */
}
