import { Component, OnInit, ViewChild } from '@angular/core';
import { HowdenAlertService, HowdenUserService } from '@howdeniberia/core-front';
import { ShowEditCredentialService } from 'src/app/features/credentials/services/show-edit-credential.service';

import { ClavesCiasApiService } from './../../../../core/services/api/claves-cias-api.service';
import { CredentialsListComponent } from './../../components/credentials-list/credentials-list.component';
import { CredentialsMapper } from './../../mappers/credential-mapper';

@Component({
  selector: 'howden-credentials-page',
  templateUrl: './credentials-page.component.html',
  styleUrls: ['./credentials-page.component.scss']
})
export class CredentialsPageComponent implements OnInit {

  private mapper: CredentialsMapper = new CredentialsMapper();
  seeAdd = false;

  @ViewChild(CredentialsListComponent) list!: CredentialsListComponent;

  constructor(
    private usrSrv: HowdenUserService,
    private alertSrv: HowdenAlertService,
    private showEditSrv: ShowEditCredentialService,
    private clavesCiasSrv: ClavesCiasApiService
  ) { }

  ngOnInit(): void {
    const r = this.usrSrv.role;
    this.seeAdd = r.roles.filter(x => x === 'HowdenClavesCiasAdmin').length > 0;
  }

  newCredential(): void {
    this.showEditSrv.open().subscribe(result => {
      if (result) {
        const newCredential = this.mapper.viewModelTonewCredentialDto(result);
        this.clavesCiasSrv.RegisterCredential(newCredential).subscribe({
          next: (data) => {
            this.list.insertOnDataraw(data);
            this.alertSrv.success('Creado', `número creado: ${data.credentialId}`);
          }
        });
      }
    });
  }

  reload(): void {
    this.list.reload();
  }

}
