<div class="flex f-fd--column f-gap--25px">
  <form [formGroup]="form">
    <mat-slide-toggle
      [formControlName]="C_DELETED"
      class="accent"
      (change)="filterData()"
    >
      Mostar Eliminadas
    </mat-slide-toggle>
  </form>
  <howden-table
    [cols]="model.columns"
    [buttons]="model.buttons"
    [pageSize]="pageSize"
    [data]="data"
    paginable="local"
    sortable="local"
    filterable="global-local"
    [highlightRow]="true"
    (buttonClicked)="buttonClick($event)"
  >
  </howden-table>
</div>
