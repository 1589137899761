export class CredentialViewModel {

  private _credentialId: number;
  private _delegation: string;
  private _company: string;
  private _user: string;
  private _password: string;
  private _url: string;
  private _deleted: boolean;

  constructor() {
    this._delegation = '';
    this._company = '';
    this._user = '';
    this._password = '';
    this._url = '';
    this._deleted = false;
    this._credentialId = 0;
  }

  /* #region  Properties */
  public get credentialId(): number {
    return this._credentialId;
  }

  public set credentialId(value: number) {
    this._credentialId = value;
  }

  public get delegation(): string {
    return this._delegation;
  }

  public set delegation(value: string) {
    this._delegation = value;
  }

  public get company(): string {
    return this._company;
  }

  public set company(value: string) {
    this._company = value;
  }

  public get user(): string {
    return this._user;
  }

  public set user(value: string) {
    this._user = value;
  }

  public get password(): string {
    return this._password;
  }

  public set password(value: string) {
    this._password = value;
  }

  public get url(): string {
    return this._url;
  }

  public set url(value: string) {
    this._url = value;
  }

  public get deleted(): boolean {
    return this._deleted;
  }

  public set deleted(value: boolean) {
    this._deleted = value;
  }
  /* #endregion */
}

