import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {
  AuthGuard,
  GENERAL_CONSTANTS,
  HowdenWithoutMenuLayoutComponent,
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  NotAuthorizedComponent,
} from '@howdeniberia/core-front';

import { CredentialsModule } from './features/credentials/credentials.module';

export const AppRoutes = [
  {
    path: '',
    component: HowdenWithoutMenuLayoutComponent,
    canActivate: [AuthGuard],
    data: {
      isLayout: true,
      roles: ['HowdenClavesCiasAdmin', 'HowdenClavesCiasBasic']
    },
    children: [
      {
        path: '',
        loadChildren: () => CredentialsModule
      }
      // {
      //   path: 'main',
      //   component: CredentialsPageComponent,
      //   title: $localize`:@@app.route.main: Credentials List`,
      //   // canActivate: [AuthGuard],
      //   // data: {
      //   //   icon: 'security',
      //   //   linkText: $localize`:@@app.route.rolestest:Roles Test`,
      //   //   roles: [],
      //   // },
      // }
    ],
  },
  {
    path: GENERAL_CONSTANTS.NotAuthorizedUrl,
    title: $localize`:@@app.route.notauthorized: No autorizado`,
    component: NotAuthorizedComponent,
  },
  NOT_AUTHORIZED_ROUTING,
  NOT_FOUND_ROUTING,
  { path: '**', redirectTo: '404' },
] as Routes;

@NgModule({
  imports: [RouterModule.forRoot(AppRoutes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
