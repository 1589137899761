<form [formGroup]="model.form" (keydown.enter)="$event.preventDefault()" class="howden-dialog-content">
  <span mat-dialog-title class="title-text">Registro de Clave</span>
  <span class="subtitle-text">Credenciales de acceso a una compañía</span>

  <mat-dialog-content>
    <div class="flex f-fd--column f-gap--25px">
      <mat-form-field>
        <mat-label>Delegación</mat-label>
        <mat-hint>No dejes espacios al principio o al final</mat-hint>
        <input
          matInput
          placeholder="delegación"
          [formControlName]="model.C_DELEGATION"
        />
        @if (model.form.get(model.C_DELEGATION)?.hasError('required')) {
          <mat-error>Obligatorio</mat-error>
        }
        @if (model.form.get(model.C_DELEGATION)?.hasError('noSpacesEndStart')) {
          <mat-error>Sin espacios</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Compañía</mat-label>
        <mat-hint>No dejes espacios al principio o al final</mat-hint>
        <input
          matInput
          placeholder="compañía"
          [formControlName]="model.C_COMPANY"
        />
        @if (model.form.get(model.C_COMPANY)?.hasError('required')) {
          <mat-error>Obligatorio</mat-error>
        }
        @if (model.form.get(model.C_COMPANY)?.hasError('noSpacesEndStart')) {
          <mat-error>Sin espacios</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Usuario</mat-label>
        <mat-hint>No dejes espacios al principio o al final</mat-hint>
        <input
          matInput
          placeholder="nombre"
          [formControlName]="model.C_USER"
        />
        @if (model.form.get(model.C_USER)?.hasError('required')) {
          <mat-error>Obligatorio</mat-error>
        }
        @if (model.form.get(model.C_USER)?.hasError('noSpacesEndStart')) {
          <mat-error>Sin espacios</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Password</mat-label>
        <mat-hint>No dejes espacios al principio o al final</mat-hint>
        <input
          matInput
          placeholder="password"
          [formControlName]="model.C_PASSWORD"
        />
        @if (model.form.get(model.C_PASSWORD)?.hasError('required')) {
          <mat-error>Obligatorio</mat-error>
        }
        @if (model.form.get(model.C_PASSWORD)?.hasError('minlength')) {
          <mat-error>3 al menos</mat-error>
        }
        @if (model.form.get(model.C_PASSWORD)?.hasError('noSpacesEndStart')) {
          <mat-error>Sin espacios</mat-error>
        }
      </mat-form-field>

      <mat-form-field>
        <mat-label>Url</mat-label>
        <mat-hint>No dejes espacios al principio o al final</mat-hint>
        <input
          matInput
          placeholder="delegación"
          [formControlName]="model.C_URL"
          (change)="validateUrl()"
        />
        @if (model.form.get(model.C_URL)?.hasError('invalidUrl')) {
          <mat-error>Debe empezar por http:// o https://</mat-error>
        }
        @if (model.form.get(model.C_URL)?.hasError('required')) {
          <mat-error>Obligatorio</mat-error>
        }
        @if (model.form.get(model.C_URL)?.hasError('noSpacesEndStart')) {
          <mat-error>Sin espacios</mat-error>
        }
      </mat-form-field>
    </div>
  </mat-dialog-content>

  <mat-dialog-actions>
    <div class="flex f-jc--end f-gap--50px">
      <button mat-flat-button color="warn" (click)="close(false)">
        <mat-icon>close</mat-icon>
        <span>Cancelar</span>
      </button>

      <button mat-flat-button color="success" (click)="close(true)">
        <mat-icon>task_alt</mat-icon>
        <span>Aceptar</span>
      </button>

      <button mat-flat-button color="accent" (click)="clean()">
        <mat-icon>cleaning_services</mat-icon>
        <span>Limpiar</span>
      </button>
    </div>
  </mat-dialog-actions>
</form>
