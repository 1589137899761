import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { CredentialsMapper } from './../../mappers/credential-mapper';
import { ICredentialEditdataDialogResult } from './../../models/ui/credential-edit-data-dialog-result';
import { EditCredentialViewModel } from './../../models/ui/edit-credential-view-model';


@Component({
  selector: 'howden-credential-edit-dialog',
  templateUrl: './credential-edit-dialog.component.html',
  styleUrls: ['./credential-edit-dialog.component.scss']
})
export class CredentialEditDialogComponent implements OnInit {

  model: EditCredentialViewModel = new EditCredentialViewModel();

  constructor(
    private dialogref: MatDialogRef<CredentialEditDialogComponent>,
    @Inject(MAT_DIALOG_DATA) protected data: ICredentialEditdataDialogResult,
  ) { }

  ngOnInit(): void {
    if (this.data?.value) {
      this.model.loadForm(this.data.value);
    }
  }

  validateUrl(): void {
    const uriVal = this.model.url;
    const res = uriVal.toLocaleLowerCase().startsWith('http://') || uriVal.toLocaleLowerCase().startsWith('https://');
    if (!res) {
      this.model.form.get(this.model.C_URL)?.setErrors({
        'invalidUrl': true
      });
    }
  }

  close(result: boolean): void {
    if (result) {
      if (!this.model.form.valid) {
        return;
      }
      const data = new CredentialsMapper().editViewModelToCredentialDto(this.model);
      this.dialogref.close(data);
    } else {
      this.dialogref.close();
    }
  }

  clean(): void {
    this.model.form.reset();
  }

}
