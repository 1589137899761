import { ICredentialDto } from 'src/app/core/models/api/credential-dto';
import { INewCredentialDto } from 'src/app/core/models/api/new-credential-dto';

import { CredentialViewModel } from '../models/ui/credential-view-model';
import { EditCredentialViewModel } from '../models/ui/edit-credential-view-model';

export class CredentialsMapper {
  /**
   *
   */
  constructor() {
  }

  /* #region  CredentialDto to ViewModel */
  public credentialDtoArrayToViewModelArray(data: ICredentialDto[]): CredentialViewModel[] {
    const res = new Array<CredentialViewModel>();
    data.forEach(x => {
      res.push(this.credentialDtoToViewModel(x));
    });
    return res;
  }

  public credentialDtoToViewModel(credentialDto: ICredentialDto): CredentialViewModel {
    const res = new CredentialViewModel();
    res.company = credentialDto.company;
    res.delegation = credentialDto.delegation;
    res.user = credentialDto.user;
    res.password = credentialDto.password;
    res.url = credentialDto.url;
    res.credentialId = credentialDto.credentialId ? credentialDto.credentialId : 0;
    res.deleted = credentialDto.deleted ? credentialDto.deleted : false;
    return res;
  }
  /* #endregion */

  /* #region  CredentialDto to EditViewModel */
  public credentialDtoArrayToEditViewModelArray(data: ICredentialDto[]): EditCredentialViewModel[] {
    const res = new Array<EditCredentialViewModel>();
    data.forEach(x => {
      res.push(this.credentialDtoToEditViewModel(x));
    });
    return res;
  }

  public credentialDtoToEditViewModel(credentialDto: ICredentialDto): EditCredentialViewModel {
    const res = new EditCredentialViewModel();
    res.company = credentialDto.company;
    res.delegation = credentialDto.company;
    res.user = credentialDto.company;
    res.password = credentialDto.company;
    res.url = credentialDto.url;
    res.credentialId = credentialDto.credentialId ? credentialDto.credentialId : 0;
    return res;
  }
  /* #endregion */

  /* #region  EditViewModel to CredentialDto */
  public editViewModelArrayToCredentialDtoArray(data: EditCredentialViewModel[]): ICredentialDto[] {
    const res = [] as ICredentialDto[];
    data.forEach(x => {
      res.push(this.editViewModelToCredentialDto(x));
    });
    return res;
  }

  public editViewModelToCredentialDto(credentialEditViewModel: EditCredentialViewModel): ICredentialDto {
    const res = {
      company: credentialEditViewModel.company,
      credentialId: credentialEditViewModel.credentialId,
      delegation: credentialEditViewModel.delegation,
      password: credentialEditViewModel.password,
      url: credentialEditViewModel.url,
      user: credentialEditViewModel.user
    } as ICredentialDto;
    return res;
  }
  /* #endregion */

  /* #region  ViewModel to CredentialDto */
  public viewModelArrayToCredentialDtoArray(data: CredentialViewModel[]): ICredentialDto[] {
    const res = [] as ICredentialDto[];
    data.forEach(x => {
      res.push(this.viewModelToCredentialDto(x));
    });
    return res;
  }

  public viewModelToCredentialDto(credentialViewModel: CredentialViewModel): ICredentialDto {
    const res = {
      company: credentialViewModel.company,
      credentialId: credentialViewModel.credentialId,
      delegation: credentialViewModel.delegation,
      password: credentialViewModel.password,
      url: credentialViewModel.url,
      user: credentialViewModel.user,
      deleted: credentialViewModel.deleted
    } as ICredentialDto;
    return res;
  }
  /* #endregion */

  /* #region  ViewModel to newCredentialDto */
  public viewModelTonewCredentialDto(credentialViewModel: CredentialViewModel): INewCredentialDto {
    const res = {
      company: credentialViewModel.company,
      delegation: credentialViewModel.delegation,
      password: credentialViewModel.password,
      url: credentialViewModel.url,
      user: credentialViewModel.user
    } as INewCredentialDto;
    return res;
  }
  /* #endregion */

}
